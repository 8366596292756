<script>
	import CaseStudy from '$lib/Components/CaseStudy/CaseStudy.svelte';
	import Button from '$lib/Components/Layout/Button/Button.svelte';
	import Grid from '$lib/Components/Layout/Grid/Grid.svelte';
	import Section from '$lib/Components/Layout/Section/Section.svelte';

	import bigfoot from '$lib/PageSections/FeaturedArticles/images/Bigfoot-Profile-square.jpg?w=250';
	import pickleball from '$lib/PageSections/FeaturedArticles/images/pickeball-thumb.jpg?w=250';
	import taxCredits from '$lib/PageSections/FeaturedArticles/images/taxcredits.jpg?w=250';
	import localLaw97 from '$routes/resources/blog/about-local-law-97-nyc/images/nyc_skyline.jpg?w=250';
	import soundProofing from '$routes/resources/blog/diy-guide-to-soundproofing-your-own-windows/images/man-installing-soundproofing-window-inserts.jpg?w=250';
	import energyEfficientWindowMyths from '$routes/resources/blog/energy-efficient-window-myths/images/energy-efficient-window-replacement.jpg?w=250';
	import homeEnergyScore from '$routes/resources/blog/improve-home-energy-score/images/Jason-Elton-DSC0060-1.jpg?w=250';
	import apartmentInsulation from '$routes/resources/blog/insulating-apartment-windows/images/womanNextToRadiator.jpg?w=250';
	import blockHeat from '$routes/resources/blog/keep-cool-save-energy-block-heat/images/trouble_keeping_house_cool.jpeg?w=250';
	import retrofit from '$routes/resources/blog/retrofit-attachment-vs-replacement/images/09-indow-windows-copypp_w1600_h1067.jpg?w=250';
	import energyEfficiency from '$routes/resources/blog/ways-to-save-with-energy-efficiency/images/Woman-thermostat-1200x800.jpg?w=250';
	import increaseValue from './images/increaseValue.png?w=250';

	export let loading = 'lazy';
	export let expanded = false;
	export let backgroundColor = 'white';
	export let title = 'Featured Articles';
	let imageClass;
	if (backgroundColor == 'white') {
		imageClass = 'border-4 border-indow-blue';
	} else if (backgroundColor == 'blue') {
		imageClass = 'border-4 border-indow-white';
	}
</script>

<Section {backgroundColor} {title}>
	<Grid columns="3" rows="3" center class="gap-x-8">
		<CaseStudy
			{imageClass}
			subgrid
			title="SAVE MONEY"
			src={taxCredits}
			href="/resources/blog/energy-tax-credits-window-inserts"
			loading={expanded ? 'eager' : loading}
		>
			<p slot="subtitle" class="text-balance">
				How to Get Energy Tax Credits with Window Inserts
			</p>
		</CaseStudy>
		<CaseStudy
			subgrid
			{imageClass}
			title="BLOCK HEAT"
			src={blockHeat}
			href="/solutions/insulate-windows-from-heat"
			loading={expanded ? 'eager' : loading}
		>
			<p slot="subtitle" class="text-balance">
				How to Keep Cool & Save Energy This Summer
			</p>
		</CaseStudy>
		<CaseStudy
			subgrid
			{imageClass}
			title="INCREASE VALUE"
			src={increaseValue}
			href="/resources/blog/increase-home-resale-value-with-inserts"
			loading={expanded ? 'eager' : loading}
		>
			<p slot="subtitle" class="text-balance">
				Increase The Resale Value Of Your Home With Inserts
			</p>
		</CaseStudy>
	</Grid>
	{#if !expanded}
		<hr class="border-b-indow-white" />
		<p>
			<Button href="/resources/featured-articles">VIEW MORE</Button>
		</p>
	{:else}
		<hr class="max-md:hidden" />
		<Grid columns="3" rows="3" center class="gap-x-8">
			<CaseStudy
				subgrid
				{imageClass}
				title="CREATURE COMFORTS"
				src={bigfoot}
				href="/bigfoot"
				loading={expanded ? 'eager' : loading}
			>
				<p slot="subtitle" class="text-balance">
					See How Bigfoot Blocks Out Noisy Neighbors
				</p>
			</CaseStudy>
			<CaseStudy
				subgrid
				{imageClass}
				title="PICKLEBALL NOISE"
				src={pickleball}
				href="/resources/blog/block-pickleball-noise"
				loading={expanded ? 'eager' : loading}
			>
				<p slot="subtitle" class="text-balance">
					Acoustic Inserts Shut Out Pickleball's Painful Pounding
				</p>
			</CaseStudy>
			<CaseStudy
				subgrid
				{imageClass}
				title="REPLACEMENT MYTHS"
				src={energyEfficientWindowMyths}
				href="/resources/blog/energy-efficient-window-myths"
				loading={expanded ? 'eager' : loading}
			>
				<p slot="subtitle" class="text-balance">
					The Downsides To Energy Efficient Window Replacement
				</p>
			</CaseStudy>
		</Grid>
		<hr class="max-md:hidden" />
		<Grid columns="3" rows="3" center class="gap-x-8">
			<CaseStudy
				subgrid
				{imageClass}
				title="SOUNDPROOFING GUIDE"
				src={soundProofing}
				href="/resources/blog/diy-guide-to-soundproofing-your-own-windows"
				{loading}
			>
				<p slot="subtitle" class="text-balance">
					What To Know About Soundproofing Your Home
				</p>
			</CaseStudy>
			<CaseStudy
				subgrid
				{imageClass}
				title="HOME ENERGY SCORE"
				src={homeEnergyScore}
				href="/resources/blog/improve-home-energy-score"
				{loading}
			>
				<p slot="subtitle" class="text-balance">
					How To Improve Your Score And Increase Home Value
				</p>
			</CaseStudy>
			<CaseStudy
				subgrid
				{imageClass}
				title="LOCAL LAW 97"
				src={localLaw97}
				href="/resources/blog/about-local-law-97-nyc"
				{loading}
			>
				<p slot="subtitle" class="text-balance">
					The Road To Sustainable Commercial Buildings Starts In NYC
				</p>
			</CaseStudy>
		</Grid>
		<hr class="max-md:hidden" />
		<Grid columns="2" rows="3" center class="gap-x-8">
			<CaseStudy
				subgrid
				{imageClass}
				title="RETROFITTING"
				src={retrofit}
				href="/resources/blog/retrofit-attachment-vs-replacement"
				{loading}
			>
				<p slot="subtitle" class="text-balance">
					Learn About Retrofit Options & Costs
				</p>
			</CaseStudy>
			<CaseStudy
				subgrid
				{imageClass}
				title="APT INSULATION"
				src={apartmentInsulation}
				href="/resources/blog/insulating-apartment-windows"
				{loading}
			>
				<p slot="subtitle" class="text-balance">
					Why Insulating Your Apartment Can Be A Great Investment
				</p>
			</CaseStudy>
			<CaseStudy
				subgrid
				{imageClass}
				title="ENERGY EFFICIENCY"
				src={energyEfficiency}
				href="/resources/blog/ways-to-save-with-energy-efficiency"
				{loading}
			>
				<p slot="subtitle" class="text-balance">
					Save Money & Save The Enivronment
				</p>
			</CaseStudy>
		</Grid>
	{/if}
</Section>
